import Modal from 'react-bootstrap/Modal';
import moment from 'moment';
import { useState, useEffect } from 'react';

function BrewEditModal(props) {

    const [brewStyle, setBrewStyle] = useState(props.brew.styl || '');
    const [brewStartTime, setBrewStartTime] = useState(moment.unix(props.brew.startTime).format("YYYY-MM-DD") || '');
    const [brewEndTime, setBrewEndTime] = useState(moment.unix(props.brew.endTime).format("YYYY-MM-DD") || '');
    const [startBLG, setStartBLG] = useState(props.brew.startBLG || '');
    const [endBLG, setEndBLG] = useState(props.brew.endBLG || '');
    const [brewColor, setBrewColor] = useState(props.brew.color || '');
    const [yeasts, setYeasts] = useState([]);
    const [yeast, setYeast] = useState(props.brew.yeast.label || 'BRAK');


    useEffect(() => {
        fetch('https://app.brewinator.lapidev.pl/API/getYeasts')
            .then((res) => res.json())
            .then((data) => {
                setYeasts(data);
            })
            .catch((err) => {
                console.log(err.message);
            });
    }, []);

    function SaveChanges() {
        let brew = props.brew;

        brew.styl = brewStyle;
        brew.startTime = moment(brewStartTime).valueOf() / 1000;
        brew.endTime = moment(brewEndTime).valueOf() / 1000;
        brew.startBLG = startBLG;
        brew.endBLG = endBLG;
        brew.color = brewColor;
        brew.yeast.label = yeast;

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(brew)
        };

        fetch('https://app.brewinator.lapidev.pl/API/saveChanges', requestOptions)
            .then(response => { response.status === 200 ? props.handleClose() : props.handleShow() });
    }

    function DeleteBrew() {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'id': props.brew.id
            },
        };

        fetch('https://app.brewinator.lapidev.pl/API/deleteBrew', requestOptions)
            .then(response => { response.status === 200 ? window.location.reload() : props.handleShow() });
    }

    return (
        <div>
            <Modal
                show={props.handleShow}
                onHide={props.handleClose}
                centered>

                <Modal.Body>

                    <label htmlFor='styl'>Styl:</label>
                    <input name='styl' type='text' value={brewStyle || ''} onChange={(e) => setBrewStyle(e.target.value)} />

                    <label>Data nastawu:</label> <br />
                    <input name='sDate' type='date' value={brewStartTime || ''} onChange={(e) => setBrewStartTime(e.target.value)} /> <br />

                    <label htmlFor='sBLG'>BLG początkowe:</label>
                    <input name='sBLG' type='text' value={startBLG || ''} onChange={e => setStartBLG(e.target.value)} />

                    <label>Data rozlewu:</label> <br />
                    <input name='eDate' type='date' value={brewEndTime || ''} onChange={e => setBrewEndTime(e.target.value)} /> <br />

                    <label htmlFor='eBLG'>BLG końcowe:</label>
                    <input name='eBLG' type='text' value={endBLG || ''} onChange={e => setEndBLG(e.target.value)} />

                    <label htmlFor='yeast'>Drożdże:</label>
                    <select name="yeast" value={yeast} onChange={e => setYeast(e.target.value)}>

                        {yeasts.map((yeast) => (
                            <option key={yeast.label} value={yeast.label}>{yeast.label}</option>
                        ))}
                    </select>


                    <div className='container'>
                        <label>Kolor piwa:</label> <br />
                        <input name='color' type='color' id='primary_color' className='field-radio' value={brewColor || '#000000'} onChange={e => setBrewColor(e.target.value)} /> <span className='container' id='color_val'></span>
                    </div>

                </Modal.Body>
                <Modal.Footer>

                    <input type='submit' value='Zapisz' name='saveChange' onClick={() => SaveChanges()}></input>
                    <input type='submit' style={{ backgroundColor: "red" }} value='Usuń' name='deleteBrew' onClick={() => { if (window.confirm('Czy na pewno chcesz usunąć ten wpis?')) DeleteBrew(); } }></input>

                </Modal.Footer>
            </Modal>
        </div>

    )
};

export default BrewEditModal;
