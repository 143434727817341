import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFeather, faTemperatureArrowDown, faTemperatureArrowUp, faTemperatureHalf } from '@fortawesome/free-solid-svg-icons'

function blgToGraviy(blg) {
    return blg / (258.6 - blg / 258.2 * 227.1) + 1;
}

function abv(startBLG, endBLG) {
    return ((76.08 * (blgToGraviy(startBLG) - blgToGraviy(endBLG)) / (1.775 - blgToGraviy(startBLG))) * (blgToGraviy(endBLG) / 0.794)).toFixed(1);
}


function Card(props) {


    let startBLG = Number(props.brewDetails.startBLG);
    let endBLG = Number(props.brewDetails.endBLG);
    let tempIcon;


    if (props.brewTemp != null) {

        let temp = Number(props.brewTemp).toFixed(1);

        if (temp <= 15)
            tempIcon = <FontAwesomeIcon color="#236794" icon={faTemperatureArrowDown} size='3x' />;
        else if (temp > 15 && temp < 24)
            tempIcon = <FontAwesomeIcon color="#509423" icon={faTemperatureHalf} size='3x' />;
        else if (temp >= 24)
            tempIcon = <FontAwesomeIcon color="#ba1818" icon={faTemperatureArrowUp} size='3x' />;

    }

    return (

        <div id="qwe" style={{ "--color-2": props.brewDetails.color }} className='card'>

            <button className='note' onClick={props.onNoteModalClick}>
                <FontAwesomeIcon icon={faFeather} size='3x' />
            </button>

            {props.brewTemp != null ?
                <span className='note'>
                    {tempIcon}
                </span>
                : null
            }

            <div className='content'>
                <p className="heading">{props.brewDetails.styl}</p>
                <p className="para">
                    Temperatura brzeczki: {props.brewTemp != null ? Number(props.brewTemp).toFixed(1) : "N/N"}°C<br />
                    Data nastawu: {moment.unix(props.brewDetails.startTime).format("DD-MM-YYYY")} <br />
                    BLG początkowe: {props.brewDetails.startBLG}° <br />
                    Data rozlewu: {moment.unix(props.brewDetails.endTime).format("DD-MM-YYYY")} <br />
                    BLG końcowe: {props.brewDetails.endBLG}° <br />
                    Drożdże: {props.brewDetails.yeast.label} <br />
                    Alc.: {abv(startBLG, endBLG)} %<br />
                </p>

                <div>
                    <input id="id" type="button" value="Edytuj" className="btn" onClick={props.onEditBrewClick}></input>
                </div>
                <div>
                    <input id="id" type="submit" value="Sczegóły" className="btn" onClick={props.onChartClick}></input>
                </div>
            </div>
        </div>

    );
}

export default Card;
