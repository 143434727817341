import '../styles/App.css'
import '../styles/Navigation.css'
import '../styles/Card.css'

import Footer from './Footer';
import Navigation from './Navigation';
import {Routes, Route, BrowserRouter} from "react-router-dom"
import Brews from '../Brews';

function App() {

  return (
    <div>
      <BrowserRouter>
        <Navigation />

        <Routes>
          <Route path="/" element={<h1 class='welcome'>Jakie piwerko wariacie :D</h1>} />
          <Route path="/brews/:id" element={<Brews />} />
        </Routes>

        <Footer />

      </BrowserRouter>
    </div>


  );
}


export default App;
