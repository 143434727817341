import Modal from 'react-bootstrap/Modal';
import { useState } from 'react';

function NoteModal(props) {

    const [note, setNote] = useState(props.brew.note || '');

    function SaveChanges() {
        let brew = props.brew;
        brew.note = note;

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(brew)
        };

        fetch('https://app.brewinator.lapidev.pl/API/saveChanges', requestOptions)
            .then(response => { response.status === 200 ? props.handleClose() : props.handleShow() });
    }

    return (
        <div>
            <Modal
                show={props.handleShow}
                onHide={props.handleClose}
                centered>

                <Modal.Body>

                    <label htmlFor='note'>Notatka:</label> <br/>
                    <textarea name="note" rows="4" cols="50" value={note || ''} onChange={e => setNote(e.target.value)} />

                </Modal.Body>
                <Modal.Footer>
                    <input  type='submit' value='Zapisz' name='saveChange' onClick={ () => SaveChanges()}></input>
                </Modal.Footer>
            </Modal>
        </div>
    )
};

export default NoteModal;
