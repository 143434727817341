import Modal from 'react-bootstrap/Modal';
import { Chart } from "react-google-charts";

function ChartModal(props) {

    const data = [
        ["Rok", "Temperatura"],
    ];

    JSON.parse(props.brew.temp).archive.forEach(element => {
        data.push([new Date(element.label * 1000).toLocaleString("pl-PL")
            , element.y]);
    });

    const chartOptions = {
        title: "Wykres temperatur",
        titlePosition: 'center',
        curveType: "function",
        legend: { position: "right" },
        series: {

            0: { color: props.brew.color},
        },

        hAxis: { title: "Data" },
        vAxis: { title: "Temperatura", viewWindow: { min: 15, max: 35 } },
    };

    return (
        <div>
            <Modal
                show={props.handleShow}
                onHide={props.handleClose}
                centered>

                <Modal.Body>
                    <div id='chartContainer' style={{ overflowX: 'scroll', width: 2000, height: 500 }}>

                        <Chart
                            chartType="LineChart"
                            width="100%"
                            height="400px"

                            data={data}
                            options={chartOptions}
                        />
                    </div>

                </Modal.Body>
            </Modal>
        </div>

    )
};

export default ChartModal;
