import { useState, useEffect } from 'react';
import { faAdd } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function Navigation() {

  const [menuItems, setMenuItems] = useState([]);

  useEffect(() => {
    fetch('https://app.brewinator.lapidev.pl/API/getDatesOfBrews')
      .then((res) => res.json())
      .then((data) => setMenuItems(data))
      .catch((err) => console.log(err.message));
  }, []);

  function CreateNewBrew() {
      fetch('https://app.brewinator.lapidev.pl/API/createNewBrew')
        .then(response => { response.status === 200 ? window.location.reload() : window.alert("Wystąpił błąd!") });
  }

  return (
    <div id="content-wrap">

      <FontAwesomeIcon onClick={ () => CreateNewBrew()} style={{ float: "right", margin: 55 }} color="#f28e1c" icon={faAdd} size='3x' />

      <link href="https://fonts.googleapis.com/css?family=Open+Sans:400,800,300" rel="stylesheet" type="text/css" />
      <nav id="menu">
        <ul>
          {menuItems.map((item) => (
            <a key={item} href={'/brews/' + item}>{item}</a>
          ))}
        </ul>

      </nav>

    </div>
  );

}

export default Navigation;
