import Card from './Card.js';
import { useParams } from 'react-router';
import { useState, useEffect } from 'react';
import BrewEditModal from './BrewEditModal';
import ChartModal from './ChartModal';
import NoteModal from './NoteModal.js';



function Brews() {

    const params = useParams()

    const [brews, setBrews] = useState([]);
    const [selectedBrew, setSelectedBrew] = useState({});
    const [showEditBrewModal, setShowEditBrewModal] = useState(false);
    const [showChartModal, setShowChartModal] = useState(false);
    const [showNoteModal, setShowNoteModal] = useState(false);

    useEffect(() => {
        if (!("Notification" in window)) {
            console.log("This browser does not support desktop notification");
          } else {
            // Notification.requestPermission();
            
            
            // var options = {
            //     body: "This is the body of the Notification",
            //     icon: "../public/logo192.png",
            //     dir: "ltr"
            //   };
            //   var notification = new Notification("Notification Demo", options);
          }
      }, []);

    useEffect(() => {
        fetch('https://app.brewinator.lapidev.pl/API/getAllBrewsInYear/' + params.id)
            .then((res) => res.json())
            .then((data) => {
                setBrews(data);
            })
            .catch((err) => {
                console.log(err.message);
            });
    }, [params.id]);


    const [actualBrewTemperature, setActualBrewTemperature] = useState({});

    useEffect(() => {
        fetch(process.env.REACT_APP_CONTROLLER_ENDPOINT + '/temperature')
            .then((res) => res.json())
            .then((data) => setActualBrewTemperature(data))
            .catch((err) => setActualBrewTemperature("N/N"));
    }, []);

    

    return (
        <div className="m_wrap">
            

            {showChartModal ?
                <ChartModal
                    handleShow={showChartModal}
                    handleClose={() => setShowChartModal(false)}
                    brew={selectedBrew} />
                : null}

            {showEditBrewModal ?
                <BrewEditModal
                    handleShow={showEditBrewModal}
                    handleClose={() => setShowEditBrewModal(false)}
                    brew={selectedBrew} />
                : null}

            {showNoteModal ?
                <NoteModal
                    handleShow={showNoteModal}
                    handleClose={() => setShowNoteModal(false)}
                    brew={selectedBrew} />
                : null}

            <div className="dx">
                {brews.map((brew) => (
                    <span data-key={brew.id} key={brew.id}>
                        <Card brewDetails={brew}
                            brewTemp={actualBrewTemperature.id === brew.id ? actualBrewTemperature.value : null}
                            onEditBrewClick={() => { setSelectedBrew(brew); setShowEditBrewModal(true); }}
                            onChartClick={() => { setSelectedBrew(brew); setShowChartModal(true); }}
                            onNoteModalClick={() => { setSelectedBrew(brew); setShowNoteModal(true); }}  />
                    </span>
                ))}
            </div>
        </div>
    );
}

export default Brews;
